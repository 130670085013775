.header {
  height: 170px;
  left: 0;
  top: 0;
  width: 100%;

  .top-block {
    height: 100px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-block {
        display: flex;

        .logo {
          height: 80px;
        }

        .applications-names {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-left: 1px solid var(--main-color);
          margin-left: 20px;
          padding-left: 20px;

          span {
            font-weight: 600;
            color: var(--main-color);
          }
        }
      }

      .right-top-block {
        display: flex;
        align-items: center;

        .languages-block {
          display: flex;
          align-items: center;
          height: 15px;

          .languages-block-item {
            font-weight: 700;
            font-size: 20px;
            padding: 0 2px;
            color: #9E9E9E;
            cursor: pointer;
            transition: .2s;

            &.active {
              color: var(--main-color);
            }
          }

          .line {
            width: 2px;
            height: 100%;
            background: #9E9E9E;
            margin:0 3px 0 3px;
          }
        }

        .change-mode-button{
          width: 30px;
          height: 30px;
          margin-left: 50px;
          position: relative;

          .light, .night {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: 0.3s;
            position: absolute;
            left: 0;
            top: 0;
          }

          .hide {
            visibility: hidden;
            transform: scale(0) rotate(90deg);
          }

          .show {
            visibility: visible;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .header {
    height: 120px;

    .top-block {
      height: 70px;

      .container {
        .logo-block {

          .logo {
            height: 50px;
          }

          .applications-names {
            display: none;
          }
        }
      }
    }
  }
}
