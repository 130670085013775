.tariffs {
  padding-bottom: 100px;

  .slide {
    width: 100%;
    background: #9E9E9E;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h3 {
      color: white;
      font-size: 30px;
    }
  }

  .tariff-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .tariff-card {
      width: 100%;
      min-width: 220px;
      max-width: 330px;
      padding: 0 10px 20px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      margin: 30px;

      .title {
        display: block;
        padding: 15px 0;
        width: 60%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background: var(--light-bg);
        font-size: 150%;
        color: var(--blackWhite-color);
        text-align: center;
        font-weight: 700;
      }

      .price {
        border-radius: 10px;
        width: 90%;
        background: var(--light-bg);
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 1.3rem;
        color: var(--main-color);
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.custom {
          font-size: 1rem;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 1.2rem;
            strong {
              font-size: 2rem;
            }
          }
        }
      }

      .characteristics-list {
        width: 90%;

        .characteristics-item {
          list-style: none;
          position: relative;
          margin-top: 15px;
          color: var(--main-color);

          &:before {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
        .true {
          &:before {
            background: url("../../../img/icons/done.svg") center;
            background-size: cover;
          }
        }
        .false {
          color: #9E9E9E;
          &:before {
            background: url("../../../img/icons/not-done.svg") center;
            background-size: cover;
          }
        }
      }

      .button {
        background: var(--light-bg);
        width: 150px;
        height: 40px;
        border: none;
        border-radius: 15px;
        color: var(--main-color);
        font-size: 17px;
        font-weight: 500;
        margin-top: 40px;
        cursor: pointer;
      }
    }
  }
}