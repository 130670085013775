.footer {
  background: #000000;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 100%;

    .title {
      color: var(--main-color);
      font-weight: 400;
      font-size: 1.1rem;
      margin: 10px 0;
    }

    .social-networks {
      display: flex;
      flex-direction: column;
      align-items: center;

      .social-networks-list {

        a {
          img {
            max-width: 40px;
            margin: 0 7px;
          }
        }
      }
    }

    .phone-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      .phone, .work_schedule {
        color: var(--main-color)
      }

      .phone {
        font-size: 1.3rem;
        font-weight: 500;
      }

      .work_schedule {
        font-weight: 500;
      }
    }

    .payment-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      .payment-list {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 70px;
          margin: 0 5px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .footer {
    .container {
      flex-direction: column;
      padding: 20px 10px;
      .phone-block, .payment-block {
        margin-top: 30px;
      }

      .social-networks {
        .social-networks-list {
          display: flex;
          justify-content: space-around;

          a{
            width: 15%;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}