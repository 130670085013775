:root {
  --main-color: #465E70;
  --blackWhite-color: #000;
  --light-bg: #fff;
  --form-elements-bg: #fff;
  --icon-bg: #D3E0E9;
}


.app {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--light-bg);

  .container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;

  }

  .content-wrapper {
    height: 100%;
  }

  .content {
    min-height: calc(100vh - 280px);
    padding-top: 0;
  }

  .light-bg {
    background: var(--light-bg);
  }

  .page-title {
    font-size: 2.2rem;
    color: var(--main-color);
    text-align: center;
  }

  .description {
    display: block;

    color: var(--main-color);
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
  }

  h1 {
    color: var(--main-color);
  }

  h2 {
    color: var(--main-color);
    font-size: 1.6rem;
    display: block;
    margin: 40px 0;
    text-align: center;
  }

  h3 {
    color: var(--main-color);
    font-size: 1.4rem;
    display: block;
    text-align: center;
  }

  p {
    color: var(--main-color);
    font-size: 1.2rem;
    line-height: 1.5;
    display: block;
  }

  ul {
    color: var(--main-color);
    font-size: 1.2rem;
  }

  form {
    input, textarea {
      background: var(--form-elements-bg);
    }

    textarea {
      resize: none;
    }

    input, textarea {
      width: 100%;
      padding: 15px;
      border-radius: 10px;
      border: none;
      outline: none;
      font-size: 15px;
    }

    input + input, input + textarea, button {
      margin-top: 15px;
    }

    button {
      font-weight: 500;
      background: var(--main-color);
      color: var(--light-bg);
      padding: 10px 15px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 15px;

      &:hover {
        background: darken(#465E70, 10%);
      }
    }
  }

  &.light {
    .light-gradient {
      background: linear-gradient(180deg, #E4EEF3 0%, #D3E0E9 100%)
    }

    .gradient {
      background: linear-gradient(180deg, #D3E0E9 0%, #A1C9E6 100%);
    }
  }


  &.night {
    --main-color: #E1E1E1;
    --light-bg: #3d3d3d;
    --blackWhite-color: #fff;
    --form-elements-bg: #E1E1E1;
    --icon-bg: #53575C;

    .light-gradient {
      background: linear-gradient(180deg, #616161 0%, #444444 100%)
    }
    
    .gradient {
      background: linear-gradient(180deg, #383838 0%, #242424 100%);
    }

    form {
      button {
        &:hover {
          background: darken(#E1E1E1, 10%);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .page-title {
    font-size: 2rem !important;
  }

  h2,.description {
    font-size: 1.4rem !important;
  }

  h3 {
    font-size: 1.2rem !important;
  }

  p, ul {
    font-size: 1rem !important;
  }

  ul {
    padding-left: 15px;
  }
}

@media (max-width: 420px) {
  .page-title {
    font-size: 1.8rem !important;
  }

  h2,.description {
    font-size: 1.2rem !important;
  }
}
