.slider-for-portfolio {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;

    .phone {
      height: 440px;
      width: 220px;
      background: var(--main-color);
      border-radius: 20px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
    }

    .description {
      width: 280px;

      font-size: 1rem !important;
      text-align: center;

      strong {
        font-size: 1.2rem;
      }
    }
  }

  .slider-button {
    height: 100px;
    width: 50px;
    border-radius: 15px;
    font-size: 200%;
    color: var(--main-color);
    border: none;
    cursor: pointer;
    outline: none;
    background: none;
  }
}

@media (max-width: 800px) {
  .slider-for-portfolio {
    align-items: flex-start;

    .slide {
      flex-direction: column;

      .phone {
        width: 200px;
        height: 400px;

        margin-right: 0;
        margin-bottom: 30px;
      }

      .description {
        width: 260px;
      }
    }

    .slider-button {
      width: 30px;

      transform: translate(0, 180px);
    }
  }
}