.navigation {
  height: 70px;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;

    .nav-list {
      display: flex;
      height: 100%;

      .nav-list-item {
        height: 100%;
        margin-right: 30px;
        color: var(--main-color);
        cursor: pointer;
        position: relative;

        > span {
          height: 100%;
          font-weight: 700;
          font-size: 1.3rem;
          transition: .1s;
          display: flex;
          align-items: center;

          &:hover {
            color: #67B865;
          }
        }

        .active {
          color: #67B865;
        }

        .submenu {
          position: absolute;
          z-index: 100;
          left: 0;
          bottom: 0;
          transform: translate(0, 100%);
          padding: 20px;
          border-radius: 10px;
          white-space: nowrap;
          display: none;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

          >span {
            font-weight: 500;
            display: block;
            transition: .1s;
            padding: 10px;

            & + span {
              margin-top: 10px;
            }

            &:hover {
              color: #67B865;
            }
          }
        }

        &:hover {
          .submenu {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .redirect-to-adminpanel-button {
      border: none;
      border-radius: 10px;

      a {
        display: block;
        padding: 5px 25px;
        color: var(--main-color);
        text-decoration-line: none;
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .navigation {
    height: 50px;

    .container {
      padding: 10px 0;

      .nav-list {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        justify-content: flex-start;
        padding: 10px 20px;
        background: var(--light-bg);
        transform: translate(-100%);
        transition: 0.2s;
        width: 80vw;

        .nav-list-item {
          height: 30px;
          margin: 5px 0;

          .submenu {
            display: none !important;
          }
        }

        &.show {
          transform: translate(0);
        }

        .close-navigation-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          position: relative;
          padding-bottom: 10px;

          .show-navigation-button {
            margin-left: 40px;
          }

          img {
            height: 50px;
          }
        }
      }

      .blackout {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 500;
        transition: 0.2s;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;

        &.show {
          opacity: 1;
        }
      }
    }

    .open-navigation-button {
      width: 25px;
      position: relative;

      &:before, &:after {
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--main-color);
      }

      &:before {
        bottom: 5px;
      }

      &:after {
        top: 5px;
      }

      span {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background: var(--main-color);
      }
    }

    .close-navigation-button {
      width: 25px;
      position: relative;

      &:before, &:after {
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--main-color);
      }

      &:before {
        bottom: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}
