.videos-delivery {
  .page-title {
    margin-top: 30px;
  }

  .subtitle {
    margin-bottom: 30px;
  }

  .links_group {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .link_button {
      width: 40%;
      max-width: 200px;
      margin: 0 20px;
      cursor: pointer;
    }
  }
}