.slider {
  width: calc(100% + 40px);
  transform: translate(-20px);

  .slick-next, .slick-prev {
    z-index: 50;
    height: 100%;
    width: 20%;
    top: 0;
    transform: none;
    
    &:before {
      content: "";
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slide-wrapper {
    height: 0;
    width: 100%;
    padding-bottom: 45.2%;
    position: relative;

    .slide-body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
 }