@import "src/components/pages/app";

.contact {
  padding-bottom: 100px;

  .slide {
    width: 100%;
    background: #9E9E9E;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h3{
      color: white;
      font-size: 30px;
    }
  }
  
  .contact-block {
    border-radius: 20px;
    padding: 80px 40px;

    display: flex;

    .social-networks {
      width: 50%;

      .social-networks-list {

        li{
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          img {
            max-width: 40px;
          }

          span {
            font-weight: 500;
            margin-left: 10px;
          }
        }
      }
    }

    .contact-form {
      width: 50%;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 500px;

        button {
          min-width: 100px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .contact {
    .contact-block {
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;

      .social-networks {
        width: 100%;
      }

      .contact-form {
        width: 100%;
        order: -1;
        margin-bottom: 40px;

        form {
          margin: 0 auto;
        }
      }
    }
  }
}