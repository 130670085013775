.portfolio {
  padding-bottom: 100px;

  .slide {
    width: 100%;
    background: #9E9E9E;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h3{
      color: white;
      font-size: 30px;
    }
  }

  .portfolio-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .portfolio-item {
      position: relative;
      width: 100%;
      max-width: 450px;
      margin: 20px;
      overflow: hidden;
      border-radius: 18px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 18px;
        transition: 0.2s;
      }

      .top-layout {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10%;
        transform: scale(0.9);
        transition: 0.2s;
        cursor: pointer;
        opacity: 0;
        border-radius: 18px;

        .portfolio-item-title {
          font-weight: 600;
          font-size: 2rem;
          color: var(--main-color);
        }
      }

      &:hover {
        .top-layout {
          transform: scale(1);
          opacity: 1;
        }

        img {
          opacity: 0;
        }
      }
    }
  }
}