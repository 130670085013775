.about_us {
  padding-bottom: 100px;

  .slide {
    width: 100%;
    height: 100%;
    background: #9E9E9E ;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h3{
      color: white;
      font-size: 30px;
    }
  }

  .page-title {
    margin-top: 2rem;
    margin-bottom: 1rem !important;
  }

  .subtitle {
    margin-bottom: 2rem;

    font-weight: 400;
    text-align: right;
  }

  .advantages-list {
    padding: 2%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .advantages-list-item {
      display: flex;
      align-items: center;
      width: 45%;
      margin-top: 40px;

      list-style-type: none;
      color: var(--main-color);

      svg {
        --advantages-list-item-icon-size: 100px;

        display: block;
        width: var(--advantages-list-item-icon-size);
        height: var(--advantages-list-item-icon-size);
        margin-right: 20px;
        padding: 15px;
        border-radius: 5px;

        background: var(--icon-bg);

        path {
          fill: var(--main-color);
        }
      }

      p {
        width: calc(100% - 80px);

        font-size: 1.4rem;
        line-height: 1.2;
      }
    }
  }

  .categories {
    span {
      display: block;
      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom: 1.2rem;
      width: fit-content;

      font-size: 1.3rem;
      color: var(--main-color);
    }

    p {
      margin: 0 auto;
      width: fit-content;

      font-size: 1.3rem;
    }
  }
}

@media (max-width: 1000px) {
  .about_us {
    .advantages-list {
      padding: 0;
      margin: 0;

      width: 90%;
      flex-direction: column;

      .advantages-list-item {
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .about_us {
    .subtitle {
      text-align: center;
    }

    .advantages-list {
      .advantages-list-item {
        svg {
          --advantages-list-item-icon-size: 80px;
        }

        p {
          width: calc(100% - 80px);
          font-size: 1.2rem;
        }
      }
    }

    .categories {
      span {
        display: block;
        margin: 0 auto;
        margin-top: 4rem;
        margin-bottom: 1.2rem;
        width: fit-content;

        font-size: 1.1rem;
        color: var(--main-color);
      }

      p {
        margin: 0 auto;
        width: fit-content;

        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 420px) {
  .about_us {
    .advantages-list {
      .advantages-list-item {
        svg {
          --advantages-list-item-icon-size: 70px;
        }

        p {
          width: calc(100% - 70px);

          font-size: 1rem;
          line-height: 1.2;
        }
      }
    }

    .categories {
      span {
        display: block;
        margin: 0 auto;
        margin-top: 4rem;
        width: fit-content;

        font-size: 1rem;
        color: var(--main-color);
      }

      p {
        margin: 0 auto;
        width: fit-content;

        font-size: 1rem;
      }
    }
  }
}