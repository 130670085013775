.player {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--main-color);
  padding: 5%;
  margin-bottom: 50px;
  margin-top: 50px;

  .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 900px;

    .video {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .loading-video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;justify-content: center;
        background: rgba(186, 186, 186, 0.8);
      }
    }
  }

  .video-previews {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 100%;
    margin-top: 50px;

    .video-preview-container {
      width: 100%;
      margin: 0 auto;
      cursor: pointer;

      .video-preview-wrapper {
        width: 100%;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        position: relative;

        .video-preview {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #bababa;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;

          .preview-title {
            color: #fff;
            font-size: 14px;
            position: absolute;
            left: 15px;
            top: 15px;
          }
          .preview-play {
            width: 10%;
            max-width: 50px;
          }
        }
      }
    }
  }
}