.delivery {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subtitle {
    margin-bottom: 40px;
  }

  .links_group {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .link_button {
      width: 40%;
      max-width: 200px;
      margin: 0 20px;
      cursor: pointer;
    }
  }
}